import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import NewsHeader from "../../components/NewsHeader";

const NewsPage = () => (
  <Layout>
    <Seo title="News—February 2010" />
	<NewsHeader />
    <h2>February 2010</h2>



	<h3 className="blogdate">
	Wednesday, February 17, 2010
	</h3>

	<h4 className="blogitemtitle">
	New song - My Ticket Home
	</h4>
	   <div className="blogitembody">
	   <p>Hi all,</p>

	<p>
	Just a quick one to let you know that I've released a live video (from
	New Zealand's Waihi Bush Music Festival 2010) of a new song called <span><i>My
	Ticket Home</i></span>. You can view at Youtube with the following link.
	</p>
	<p>
	<a href="http://www.youtube.com/watch?v=0vNkNlJdB00">http://www.youtube.com/watch?v=0vNkNlJdB00</a>
	</p>
	<p>
	I'm off to play the rest of my February shows in the south island of New
	Zealand, woo hoo!
	</p>
	<p>
	Jesse
	</p>
	   </div>
	   	   <div className="blogitemfooter">
	   <p>posted by Jesse @ 12:09 +1300</p>
	   </div>





	<p>
		<Link to="/news/">Go back to the news index page</Link>
	</p>
  </Layout>
);

export default NewsPage;
